import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { History } from "history";
import createReducer from "./reducers";
import rootSaga from "../app/sagas";

const configureStore = (initialState: any = {}, history: History) => {
  let composeEnhancers: any = compose;
  const reduxSagaMonitorOptions: any = {};

  if ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});
  }

  // init middlewares
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const middlewares = [sagaMiddleware, routerMiddleware(history)];

  const enhancers = composeEnhancers(applyMiddleware(...middlewares));

  const store: any = createStore(
    createReducer(),
    enhancers
  );

  (store as any).runSaga = sagaMiddleware.run(rootSaga);

  if ((module as any).hot) {
    (module as any).hot.accept("./reducers", () => {
      store.replaceReducer(createReducer((store as any).injectedReducers));
    })
  }

  return store as any;
}

export default configureStore;
