// amplify
import Amplify, { API } from "aws-amplify";
import awsExports from "../aws-exports";
Amplify.configure(awsExports);

export type TranslationParams = {
  id?: string;
  item_key: string;
  current_value: string;
  language: "en" | "fr";
  previous_value?: string;
}

export const createTranslation = async (params: TranslationParams) => {
  const data = {
    body: {
      ...params
    }
  }
  const apiData = await API.post('textapi', '/text', data);
  return apiData;
}

export const updateTranslation = async (params: TranslationParams) => {
  const data = {
    body: {
      ...params
    }
  }
  const apiData = await API.put('textapi', '/text', data);
  return apiData;
}

export const getTranslations = async () => {
  const apiData = await API.get('textapi', '/text', {});

  // format to key-value pairs
  const translations: Record<string, any> = {};
  apiData.data.forEach((el: any) => translations[el.item_key] = el);

  return translations;;
}
