import classNames from "classnames";
import React, { FC, ReactElement, useEffect } from "react";
import useGeneric, {GenericType} from "../../../../hooks/useGeneric";
import { Translation } from "../../../../models/Translation";
import history from "../../../../utils/history";
import {translate} from "../../../../utils/translations";

import "./styles.scss";

interface MenuProps {
  translations: Record<string, Translation>;
  subtext: GenericType<string>;
}

const Menu: FC<MenuProps> = (props: MenuProps): ReactElement => {
  const { translations, subtext } = props;

  const isMenuActive = useGeneric<boolean>(false);
  const t = (key: string): string => translate(`home.menu.${key}`, translations);
 
  const hoverProps = {
    onMouseEnter: () => isMenuActive.setValue(true),
    onMouseLeave: () => isMenuActive.setValue(false)
  }

  // const menuSubOptions: Record<string, Record<string, string>> = {
  //   "mission": {
  //     "top-right": "offer",
  //     "bottom-left": "values",
  //     "bottom-right": "interactions"
  //   },
  //   "services": {
  //     "top-left": "framework",
  //     "bottom-left": "data-analysis",
  //     "bottom-right": "resources"
  //   },
  //   "track-records": {
  //     "top-left": "background",
  //     "top-right": "case-studies",
  //     "bottom-right": "publications"
  //   },

  const selectMenuOption = (key: string): void => {
    if (key === "contact") history.push("/?view=contact")
    else history.push(`/${key}`);
  }

  const getLabel = (key: string, position: string): string => t(position.replace("-", "_"));
  const getCaptionLabel = (position: string): string => t(position.replace("-", "_") + ".caption");
  const stopClick = (e: any): void => {
    e.preventDefault()
    e.stopPropagation();
  }

  return (
    <div {...hoverProps }>
    <div className="menu">
      <div className={classNames("menu__background-donut background-blue--light", { active: isMenuActive.value})}>
        <div className={classNames("menu__background-hole", { active: isMenuActive.value})} />
      </div>

      <div className={classNames("menu__foreground", { active: isMenuActive.value })}>
        {["mission", "services", "track-record", "contact"].map((el: string, index) => {
          const position = el === "mission" ? "top-left"
            : el === "services" ? "top-right"
            : el === "track-record" ? "bottom-left"
            : "bottom-right"

          return (
            <div
              key={index}
              className={classNames("menu__slice", `menu__${position}`, "pointer")}
              onClick={() => selectMenuOption(el)}
              onMouseOver={() => {
                const caption = getCaptionLabel(position);
                if (caption) subtext.setValue(caption);
                else subtext.clear(); 
              }}
              onMouseLeave={() => subtext.clear()}
            >
              <span className="menu__slice-label">{getLabel(el, position)}</span>
              <div
                className={classNames("menu__slice-inner", `menu__${position}-inner`)}
                onMouseOver={stopClick}
                onClick={stopClick}
              />
            </div>
          )
        })}
      </div>
     </div>
   </div>
  )
}

export default Menu;
