import React, { FC, ReactElement } from "react";
import qs from "query-string";
import { selectCurrentParams, selectTranslations } from "../../redux/app/selectors";
import AppHeader from "./components/AppHeader";
import Menu from "./components/Menu";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import Contact from "../Contact";
import { translate } from "../../utils/translations";

// import styles
import "./styles.scss";
import useGeneric from "../../hooks/useGeneric";

interface HomeProps {
  queryParams: string;
  translations: Record<string, any>;
}

const Home: FC<HomeProps> = (props: HomeProps): ReactElement => {
  const { queryParams, translations } = props;

  const { view } = qs.parse(queryParams);

  const t = (key: string): string => translate(`home.${key}`, translations);

  const subtext = useGeneric<string>(t("slogan"));

  return (
    <>
      <AppHeader {...{ translations }} />
      <div className="home">
        <div className="home__header text-center">
          <p className="h4 m0" dangerouslySetInnerHTML={{__html: t("header")}} />
          <p className="h6 m0" dangerouslySetInnerHTML={{ __html: t("subheader")}} />
        </div>
        {view === "contact" ? (
          <Contact {...{ translations }} />
        ) : (
          <Menu {...{ translations, subtext }} />
        )}
        <p className="h5 text-center" dangerouslySetInnerHTML={{__html: subtext.value }}/>
      </div>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  queryParams: selectCurrentParams,
  translations: selectTranslations
});

export default connect(mapStateToProps, null)(Home);
