interface BasicStatus {
  loading: boolean;
  error: boolean;
  success: boolean;
  payload?: any;
};

export const BASIC_LOADING_STATE = {
  loading: true,
  error: false,
  success: false
};

export const BASIC_ERROR_STATE = {
  loading: false,
  error: true,
  success: false
};

export const BASIC_SUCCESS_STATE = {
  loading: false,
  error: false,
  success: true
};

export default BasicStatus;
