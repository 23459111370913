import Route from "./models/Route";
import Home from "./views/Home";
import Services from "./views/Services";
import Mission from "./views/Mission";
import TrackRecord from "./views/TrackRecord";

export const MAIN_ROUTES = [
  {
    id: "track-record",
    name: "Track Record",
    path: "/track-record",
    component: TrackRecord
  },
  {
    id: "mission",
    name: "Mission",
    path: "/mission",
    component: Mission
  },
  {
    id: "services",
    name: "Services",
    path: "/services",
    component: Services
  }
];

const ROUTES: Route[] = [
  {
    id: "home",
    name: "Home",
    path: "/",
    component: Home
  },
  ...MAIN_ROUTES
]

export default ROUTES;
