import emailjs from 'emailjs-com';
import React, { FC, ReactElement } from "react";
import { Translation } from "../../models/Translation";
import { translate } from "../../utils/translations";
import BasicStatus, {
  BASIC_ERROR_STATE,
  BASIC_LOADING_STATE,
  BASIC_SUCCESS_STATE
} from "../../models/BasicStatus";
import useGeneric from "../../hooks/useGeneric";

import "./styles.scss";

interface ContactFormProps {
  closeForm: () => void;
  translations: Record<string, Translation>;
}

const ContactForm: FC<ContactFormProps> = (props: ContactFormProps): ReactElement => {
  const { closeForm, translations } = props;

  const status = useGeneric<BasicStatus>({ loading: false, success: false, error: false });

  const t = (key: string): string => translate(`contact_form.${key}`, translations);

  const clearForm = (): void => {
    const form = document.getElementById("header-contact-form");
    if (form) (form as any).reset();
  }

  const onClose = () => {
    status.clear();
    clearForm();
    closeForm();
  }

  const sendEmail = (e: any) => {
    e.preventDefault();
    status.setValue(BASIC_LOADING_STATE);
 
    emailjs.sendForm('service_g3c3v4l', 'template_dj3mnpa', e.target, 'user_ovLKnLxrbBP1aTtoWys2j')
      .then((result) => {
        status.setValue(BASIC_SUCCESS_STATE);
        clearForm();
      }, (error) => {
        status.setValue({...BASIC_ERROR_STATE, payload: error.text});
      });
  }

  return (
    <form className="contact-form" onSubmit={sendEmail} id="header-contact-form">
      <input type="hidden" name="contact_number" />
      <div className="contact-form__actions">
        <i className="fa fa-times fa-lg contact__close" aria-hidden="true" onClick={onClose} />
      </div>

      {status.value.loading ? (
        <>
          <p style={{ color: "white", textAlign: "center", paddingTop: "15%", fontSize: "larger" }}>
            <i className="fa fa-spinner fa-spin mr10" />
            <em>Sending...</em>
          </p>
        </>
      ) : status.value.success ? (
        <div style={{ paddingTop: "15%"}}>
          <p style={{ textAlign: "center" }}><i className="fa fa-check-circle fa-2x" style={{ color: "#4BB543"}}/></p>
          <p style={{ color: "white", textAlign: "center", fontSize: "larger" }}>
            Message sent.
          </p>
        </div>
      ) : (
        <>
          {status.value.error ?
            <p style={{color: "red", fontSize: "14px", padding: "0px 36px 0px 36px"}}>
              <i className="fa fa-times-circle mr10" />
              Message could not be sent. Please verify your inputs and try again.
            </p>
            : <h2 dangerouslySetInnerHTML={{__html: t("header")}} />
          }
          <div className="contact-form__content">
            <div className="contact-form__input">
              <input
                type="text"
                name="user_name"
                className="contact-form__name"
                placeholder={t("name")}
              />
              <input
                type="text"
                name="user_business"
                className="contact-form__business"
                placeholder={t("business")}
              />
            </div>
            <input
              type="email"
              name="user_email"
              className="contact-form__email"
              placeholder={t("email")}
            />
            <textarea
              name="message"
              className="contact-form__message"
              placeholder={t("message")}
            />
          </div>
          <div className="contact-form__footer">
            <button
              type="submit"
              className="contact-form__submit"
            >
              {t("send")}
            </button>
          </div>
        </>
      )}
    </form>
  );
}

export default ContactForm;
