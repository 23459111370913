import { init } from 'emailjs-com';
import React, { FC, ReactElement } from 'react';
import { Route, Switch, withRouter } from "react-router";

import RouteContainer from "./routeContainer";

// import styles
import './styles.scss';

const App: FC = (): ReactElement => {

  init("user_ovLKnLxrbBP1aTtoWys2j");

  return (
    <main className="app-container">
      <RouteContainer />
    </main>
  );
}

export default withRouter(App);
