import { AnyAction } from "redux";
import { Locale } from "../../models/Locale";


import {
  SET_LOCALE,
  FETCH_TRANSLATIONS,
  FETCH_TRANSLATIONS_SUCCESS,
  FETCH_TRANSLATIONS_ERROR,
} from "./constants";

/**
 *  Dispatched to set the app language
 *
 *  @param {Locale} locale The locale (en, fr)
 *  @return {object} An action object with a type of SET_LOCALE passing the preferred locale
 */
export const setLocale = (locale: Locale): AnyAction => {
  return {
    type: SET_LOCALE,
    locale
  }
}

/**
 *  Dispatched to fetch the translations for the given locale
 *
 *  @param {Locale} locale The locale (en, fr)
 *  @return {object} An action object with a type of FETCH_TRANSLATIONS passing the locale
 */
export const fetchTranslations = (locale: Locale): AnyAction => {
  return {
    type: FETCH_TRANSLATIONS,
    locale
  }
}

/**
 *  Dispatched when fetching the translations succeeds
 *
 *  @param {Record<string, string>} translations The translations object
 *  @return {object} An action object with a type of FETCH_TRANSLATIONS_SUCCESS passing the translations
 */
export const fetchTranslationsSuccess = (translations: Record<string, string>): AnyAction => {
  return {
    type: FETCH_TRANSLATIONS_SUCCESS,
    translations
  }
}

/**
 *  Dispatched when fetching the translations fails
 *
 *  @param {object} error The error
 *  @return {object} An action object with a type of FETCH_TRANSLATIONS_ERROR passing the error
 */
export const fetchTranslationsError = (error: object): AnyAction => {
  return {
    type: FETCH_TRANSLATIONS_ERROR,
    error
  }
}

