import { AnyAction } from "redux";
import { actionChannel, all, call, fork, put, select, take, takeEvery, takeLatest } from "redux-saga/effects";
import { Translation } from "../../models/Translation";

import ALL_ROUTES from "../../routes";
import { getTranslations } from "../../services/TranslationsService";
import history from  "../../utils/history";
import { fetchTranslationsError, fetchTranslationsSuccess } from "./actions";
import { FETCH_TRANSLATIONS } from "./constants";

const sanitizePath = (path: string): string => {
  const splitPath = path.split("");
  if (splitPath.length > 1 && splitPath[splitPath.length - 1] === "/") {
    return splitPath.slice(0, splitPath.length - 1).join("");
  }
  return path;
}

function* routeGuard (action: AnyAction): any {
  const { pathname } = action.payload.location;
  const sanitizedPath = sanitizePath(pathname);
  const existingPaths = ALL_ROUTES.map(el => el.path);

  if (!existingPaths.includes(sanitizedPath)) {
    history.push("/");
  };
}

function* fetchTranslations (): any {

  try {
    const translations = yield call(getTranslations);
    yield put(fetchTranslationsSuccess(translations));
    const values: any[] = Object.values(translations)
    values
      .filter((el: Translation) => el.item_key.includes("color/"))
      .forEach((item: Translation) => {
        const { item_key, current_value } = item;
        const color  = item_key.split("color/")[1];
        document.documentElement.style.setProperty(color, current_value);
      })
  } catch (err) {
    console.log("Error fetching translations: ", err);
    yield put(fetchTranslationsError({ error: err }));
  }
}

export default function* rootSaga() {
  yield fork(fetchTranslations);
  yield takeEvery(FETCH_TRANSLATIONS, fetchTranslations);
  yield takeEvery("@@router/LOCATION_CHANGE", routeGuard);
}
