import { ConnectedRouter } from "connected-react-router";
import React, { FC, ReactElement, useState, useEffect } from 'react';
import { Provider } from "react-redux";
import App from ".";

import configureStore from "../../redux/store/configureStore";
import history from "../../utils/history";

const AppWrapper: FC = (): ReactElement => {
  const [ready, setReady] = useState<boolean>(false);
  const [store, setStore] = useState<any>(undefined);

  useEffect(() => {
    //create redux store
    const initialState = {};
    setStore(configureStore(initialState, history));
    setReady(true);
  }, []);

  return ready ? (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  ) : <></>;
}

export default AppWrapper;
