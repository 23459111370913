import classNames from "classnames";
import React, { FC, ReactElement } from "react";
import logo from "../../../../assets/logo512.png";
import ContactForm from "../../../../components/ContactForm";
import useGeneric from "../../../../hooks/useGeneric";
import {Translation} from "../../../../models/Translation";
import history from "../../../../utils/history";
import {translate} from "../../../../utils/translations";

// import styles
import "./styles.scss";

interface AppHeaderProps {
  translations: Record<string, Translation>;
}

const AppHeader: FC<AppHeaderProps> = (props: AppHeaderProps): ReactElement => {
  const { translations } = props;

  const t = (key: string): string => translate(`app_header.${key}`, translations);
 
  const showContactForm = useGeneric<boolean>(false);

  return (
    <>
      <div className="app-header flexbox spacebetween">
        <div className="app-header__logo flexbox pointer" onClick={() => history.push("/")}>
          <p className="text-accent-dark h5 m0">data</p>
          <img src={logo} height={50} width={50} alt="logo" />
          <p className="text-accent-dark h5 m0">time</p>
        </div>
        <div className="app-header__contact">
          <button
            className={classNames("button", "button--contact", { hidden: showContactForm.value })}
            onClick={() => showContactForm.setValue(true)}
          >
            {t("contact_button")}
          </button>
        </div>
      </div>
      <div className={classNames("contact__slideout", { "contact__slideout--visible": showContactForm.value })}>
        <ContactForm closeForm={() => showContactForm.setValue(false)} translations={translations} />
      </div>
    </>
  )
}

export default AppHeader;
