import React, { FC, ReactElement } from "react";
import qs from "query-string";
import BlockLayout from "../../components/BlockLayout";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import {selectCurrentParams, selectTranslations} from "../../redux/app/selectors";
import {translate} from "../../utils/translations";
import { Translation } from "../../models/Translation";

interface TrackRecordProps {
  translations: Record<string, Translation>;
  queryParams: string;
}

const TrackRecord: FC<TrackRecordProps> = (props: TrackRecordProps): ReactElement => {
  const { translations, queryParams } = props;

  const { view, section } = qs.parse(queryParams)

  const t = (key: string): string => translate(`track_records.${key}`, translations);

  return (
    <BlockLayout
      pageTitle={t("page_title")}
      sectionKeys={[t("nav.section_1"), t("nav.section_2"), t("nav.section_3")]}
      navPosition="right"
      view={view as string}
      routeId="track-record"
      scrollToId={section as string}
      translations={translations}
    >
      <div className="block__section">
        <p className="h3 m0" dangerouslySetInnerHTML={{__html: t("section_1.header")}} />
        <p
          className="h7 block__section-body"
          dangerouslySetInnerHTML={{__html: t("section_1.body")}}
          style={{ marginTop: "3rem", paddingBottom: "2rem" }}
        />
      </div>
      <div className="block__section">
        <p className="h3 m0" dangerouslySetInnerHTML={{__html: t("section_2.header")}} />
        <p
          className="h7 block__section-body"
          dangerouslySetInnerHTML={{__html: t("section_2.body")}}
          style={{ marginTop: "3rem", paddingBottom: "2rem" }}
        />
      </div>
      <div className="block__section">
        <p className="h3 m0" dangerouslySetInnerHTML={{__html: t("section_3.header")}} />
        <p
          className="h7 block__section-body"
          dangerouslySetInnerHTML={{__html: t("section_3.body")}}
          style={{ marginTop: "3rem", paddingBottom: "2rem" }}  
        />
      </div>
   </BlockLayout>
  )
}

const mapStateToProps = createStructuredSelector({
  translations: selectTranslations,
  queryParams: selectCurrentParams
});

export default connect(mapStateToProps, null)(TrackRecord);
