import { Route, Switch, withRouter } from "react-router";

import MAIN_ROUTES from "../../routes";
import Home from "../../views/Home";
import React, { FC, ReactElement } from "react";
import qs from "query-string";
import { selectCurrentParams, selectTranslations, selectTranslationsLoading } from "../../redux/app/selectors";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { translate } from "../../utils/translations";

interface RouteContainerProps {
  isLoading: boolean;
}

const RouteContainer: FC<any> = (props: RouteContainerProps): ReactElement => {
  const { isLoading } = props;

  return isLoading ? <></> : (
    <>
      <Switch>
        {MAIN_ROUTES.map((route) => (
          <Route exact={true} key={route.id} {...route} />
        ))}
        <Route path="*" component={Home} />
      </Switch>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  isLoading: selectTranslationsLoading
});

export default connect(mapStateToProps, null)(RouteContainer);
