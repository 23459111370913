import produce from "immer";
import { AnyAction, Reducer } from "redux";

import BasicStatus, {
  BASIC_ERROR_STATE,
  BASIC_LOADING_STATE,
  BASIC_SUCCESS_STATE
} from "../../models/BasicStatus";
import { Locale } from "../../models/Locale";
import I18N from "../../translations/main";

import {
  FETCH_TRANSLATIONS,
  FETCH_TRANSLATIONS_ERROR,
  FETCH_TRANSLATIONS_SUCCESS,
  SET_LOCALE
} from "./constants";

export interface AppState {
  locale: Locale;
  translations: Record<string, any>;
  statuses: {[statusKey: string]: BasicStatus};
}

export const initialState: AppState = {
  locale: "en",
  translations: I18N,
  statuses: {}
};

const appReducer: Reducer<AppState, AnyAction> = (
  state = initialState,
  action: AnyAction
): AppState =>
  produce(state, (draft) => {
    switch (action.type) {

      case SET_LOCALE:
        draft.locale = action.locale;
        break;

      case FETCH_TRANSLATIONS:
        draft.statuses["translations"] = {...BASIC_LOADING_STATE};
        break;

      case FETCH_TRANSLATIONS_SUCCESS:
        draft.translations = action.translations;
        draft.statuses["translations"] = {...BASIC_SUCCESS_STATE};
        break;

      case FETCH_TRANSLATIONS_ERROR:
        draft.statuses["translations"] = {...BASIC_ERROR_STATE};
        break;
    }
  });

export default appReducer;
