// default values, to be overwritten by api data
const I18N: Record<string, string> = {
  "color/--app-color-dark": "#001449",
  "color/--app-color-medium": "rgba(108, 146, 255, 0.97)",
  "color/--app-color-light": "rgba(108, 146, 255, 0.25)",
  "color/--accent-color": "#00bc93",
  "color/--accent-color--light": "rgba(0, 188, 147, 0.4)",
  "color/--text-color-light": "#A2A2A2",
  "color/--text-color-dark": "#434343",
  "color/--text-acronym": "#6600CC",
  "color/--white": "#FFFFFF",
  "color/--black": "#121212",
  "color/--gray-lightest": "#E8E8E8",
  "color/--gray-light": "#A2A2A2",
  "color/--gray-medium": "#7E7E7E",
  "color/--gray-dark": "#434343",
  "color/--gray-darkest": "#262626",

  "app_header.contact_button": "Let's talk data",

  "home.header": "Data2time",
  "home.subheader": "Customized Life Data Science Services",
  "home.slogan": "Make you data save your time",
  "home.menu.top_left": "Mission",
  "home.menu.top_right": "Services",
  "home.menu.bottom_left": "Track Records",
  "home.menu.bottom_right": "Contact",
  "home.menu.top_left.caption": "Customize services, bring value, deliver on time",
  "home.menu.top_right.caption": "Life data analyses, data protection, quality systems, method prototyping",
  "home.menu.bottom_left.caption": "20 years of experience in industrial life data sciences",
  "home.menu.bottom_right.caption": "",

  "contact.back": "back",
  "contact.phone": "41799015945",
  "contact.email": "info@data2time.com",
  "contact.linkedin": "https://ch.linkedin.com/in/jeromewojcik",
  "contact.address": "Data2time Sàrl, Rue du Four 1, 1055 Froideville, Switzerland",

  "contact_form.header": "Let's talk&nbsp;<span class=blink>_</span>",
  "contact_form.name": "Name",
  "contact_form.business": "Business",
  "contact_form.email": "E-mail",
  "contact_form.message": "Message",
  "contact_form.send": "send",

  "mission.page_title": "Mission",
  "mission.nav.section_1": "Offer",
  "mission.nav.section_2": "Values",
  "mission.nav.section_3": "Interactions",
  "mission.section_1.header": "Offer (header)",
  "mission.section_1.body": "Offer (body)",
  "mission.section_2.header": "Values (header)",
  "mission.section_2.body": "Values (body)",
  "mission.section_3.header": "Interactions (header)",
  "mission.section_3.body": "Interactions (body)",

  "track_records.page_title": "Track Records",
  "track_records.nav.section_1": "Background",
  "track_records.nav.section_2": "Case Studies",
  "track_records.nav.section_3": "Publications",
  "track_records.section_1.header": "Background (header)",
  "track_records.section_1.body": "Background (body)",
  "track_records.section_2.header": "Case Studies (header)",
  "track_records.section_2.body": "Case Studies (body)",
  "track_records.section_3.header": "Publications (header)",
  "track_records.section_3.body": "Publications (body)",

  "services.page_title": "Services",
  "services.nav.section_1": "Framework",
  "services.nav.section_2": "Data Analysis",
  "services.nav.section_3": "Resources",
  "services.section_1.header": "Framework (header)",
  "services.section_1.body": "Framework (body)",
  "services.section_2.header": "Data Analysis (header)",
  "services.section_2.body": "Data Analysis (body)",
  "services.section_3.header": "Resources (header)",
  "services.section_3.body": "Resources (body)"
}

export default I18N;
