import React, { FC, ReactElement } from "react";
import { Translation } from "../../models/Translation";
import history from "../../utils/history";
import {translate} from "../../utils/translations";

import "./styles.scss";

interface ContactProps {
  translations: Record<string, Translation>;
}

const Contact: FC<ContactProps> = (props: ContactProps): ReactElement => {
  const { translations } = props;

  const t = (key: string): string => translate(`contact.${key}`, translations);
 
  return (
    <div className="contact">
      <div className="contact__bar">
        <div className="contact__icons">
          <a href={`mailto:${t("email")}`}>
            <div className="icon icon-fill">
              <i className="fa fa-envelope fa-3x" />
            </div>
          </a>
          <a href={`tel:${t("phone")}`}>
            <div className="icon icon-fill">
              <i className="fa fa-phone fa-3x" />
            </div>
          </a>
          <span onClick={() => window.open(t("linkedin"))}>
            <div className="icon icon-fill">
              <i className="fa fa-linkedin fa-3x" />
            </div>
          </span>
        </div>
        <p className="pointer text-center text-muted mt30" onClick={() => history.push("/")}>{t("back")}</p>
      </div>
    </div>
  )
}

export default Contact;
