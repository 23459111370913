import { connectRouter } from "connected-react-router";
import { combineReducers, Reducer } from "redux";

import history from "../../utils/history";
import appReducer from "../app/reducer";

const createReducer = (injectedReducers = {}): Reducer => {
  const rootReducer = combineReducers({
    router: connectRouter(history),
    main: appReducer,
    ...injectedReducers
  })

  return rootReducer;
}

export default createReducer;
