import classNames from "classnames";
import React, { FC, ReactElement, useEffect } from "react";
import useGeneric from "../../hooks/useGeneric";
import history from "../../utils/history";
import { translate } from "../../utils/translations";
import logo from "../../assets/logo512.png";

// import styles
import "./styles.scss";

interface BlockLayoutProps {
  pageTitle: string;
  sectionKeys: string[];
  navPosition: "right" | "left";
  children: JSX.Element[];
  view?: string;
  scrollToId?: string;
  routeId: string;
  translations: Record<string, any>;
}

const BlockLayout: FC<BlockLayoutProps> = (props: BlockLayoutProps): ReactElement => {
  const {
    pageTitle,
    sectionKeys = [],
    navPosition,
    children = [],
    view = "1",
    routeId,
    scrollToId,
    translations
  } = props;

  const activeSectionIndex = useGeneric<number>(parseInt(view) - 1);

  useEffect(() => {
    if (scrollToId) {
      console.log(scrollToId)
      const element = document.getElementById(scrollToId);
      if (element) {
        console.log(element)
        element.scrollIntoView();
      }
    }
  }, [scrollToId]);

  const t = (key: string): string => translate(key, translations);

  return (
    <>

      <div className="split-layout__header">
        <div className="app-header__logo flexbox pointer" onClick={() => history.push("/")}>
          <p style={{ marginLeft: "0px", color: "var(--app-color-dark)"}} className="text-dark h5 m0">data</p>
          <img src={logo} height={50} width={50} alt="logo" />
          <p className="h5 m0" style={{ marginLeft: "0px", color: "var(--app-color-dark)"}}>time</p>
        </div>
      </div>

      <div
        className={classNames("split-layout", {
          "split-layout--left": navPosition === "left",
          "split-layout--right": navPosition === "right",
        })}
      >

        <div className="section__nav">
          <div className="section__nav--inner">
            <p className="h3 m0" dangerouslySetInnerHTML={{__html: pageTitle}} />
            <ul className="simple-list">
              {sectionKeys.map((key, index) => (
                <li
                  key={[pageTitle, "section", key, index].join("-")}
                  className={classNames("section__nav-item", { "section__nav-item--active": activeSectionIndex.value === index })}
                >
                  <p
                    className="m0 h5 pointer flexbox"
                    onClick={() => {
                      activeSectionIndex.setValue(index)
                      history.push(`/${routeId}?view=${index + 1}`)
                    }}
                  >
                    <span dangerouslySetInnerHTML={{__html: key}} />
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="section__content">
          <div className="section__content--inner">
            {children[activeSectionIndex.value] || <></>}
          </div>
        </div>

      </div>

      <div className="split-layout__footer">
        <h6 className="h8 text-muted text-center">{t("contact.address")}</h6>
      </div>
    </>
  )
}

export default BlockLayout;
