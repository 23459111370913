import { useState } from "react";

export interface GenericType<T> {
  value: T;
  setValue: (newValue: T) => void;
  clear: () => void;
}

const useGeneric = <T extends {} | undefined> (
  initialState: T
): GenericType<T> => {

  const [value, setValue] = useState<T>(initialState);

  const clear = (): void => setValue(initialState);


  return {
    value,
    setValue,
    clear
  }
}

export default useGeneric;
